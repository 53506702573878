import React from 'react';
import styled from 'styled-components';
import ComponentStyles from '../brand-styles/components/AccordionComponent.styles';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { colors, breakpoints } from '../style-utilities/variables';
const Div = styled.div`
  .accordion__title {
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 16px;
    }
  }
  .accordion__arrow {
    top: 0;
    float: right;
    margin-top: 0;
    position: relative;
  }
  .accordion__plusMinus {
    top: 0;
    float: right;
    margin-top: 0;
    position: relative;
  }
  .hideBodyTitle {
    background: white;
  }
  .accordion__body {
    padding: 20px 40px 40px;
    color: gray;
    background: white;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    color: ${colors.bodyText};
    @media (min-width: ${breakpoints.tablet}) {
      font-size: 20px;
    }
  }
  .icon {
    position: relative;
    float: right;
  }

  .plus {
    display: none;
    transition-duration: 0.2s;
    transition-property: height;
  }
  .minus {
    display: inline;
    transition-duration: 0.2s;
    transition-property: height;
  }

  .hideBodyTitle {
    .plus {
      display: inline;
    }
    .minus {
      display: none;
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const AccordionComponent = ({ title, content, clicked, accState, symbol }) => {
  return (
    <Div>
      <Accordion className="accordion" onClick={clicked}>
        <AccordionItem expanded={accState}>
          <AccordionItemTitle hideBodyClassName="hideBodyTitle">
            <h3 className="title">{title}</h3>
            {/* <span className="accordion__arrow" role="presentation" /> */}
            <div className="accordion__plusMinus plus">
              <FontAwesomeIcon id="icon" icon={faPlus} />
            </div>
            <div className="accordion__plusMinus minus">
              <FontAwesomeIcon id="icon" icon={faMinus} />
            </div>
          </AccordionItemTitle>
          <AccordionItemBody>{content}</AccordionItemBody>
        </AccordionItem>
      </Accordion>
    </Div>
  );
};

export default AccordionComponent;
