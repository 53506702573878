import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ComponentStyles from '../brand-styles/pages/faqs.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import AccordionComponent from '../components/AccordionComponent';
import PagesHero from '../components/PagesHero';
import Heading from '../components/Heading';
import ContactBox from '../components/ContactBox';
import { breakpoints } from '../style-utilities/variables';

const Div = styled.div`
  .hero-section {
    background: ${props => `url(${props.bgImage}) no-repeat center`};
    background-size: cover;
  }
  .Hero {
    display: flex;
    align-items: center;
    height: 400px;
  }
  .Hero h1 {
    margin: auto;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

const MainSection = styled.div`
  padding: 40px;
  display: grid;
  display: -ms-grid;
  grid-template-columns: 70% 30%;
  -ms-grid-columns: 70% 30%;
  .Options {
    -ms-grid-column: 1;
  }

  .Contact {
    text-align: center;
    -ms-grid-column: 2;
  }
  .Contact h2 {
    line-height: 1.4;
  }
  #icon,
  h3 {
    margin: 0 10px;
    display: inline;
  }
  #icon {
    transform: scaleX(-1);
  }
  @media (max-width: ${breakpoints.tablet}) {
    display: block;
    padding: 50px 0 0;
    .Contact {
      margin-top: 40px;
    }
  }
`;

class Faqs extends Component {
  state = {
    activeAcc: 1
  };

  accordionChangeHandler = clickedAcc => {
    this.setState({ activeAcc: clickedAcc });
  };

  render() {
    const { edges: faqs } = this.props.data.allWordpressWpFaq;
    const heroImageUrl = this.props.data.wordpressPage.acf.hero_image ? this.props.data.wordpressPage.acf.hero_image.source_url : '';

    return (
      <Div bgImage={heroImageUrl}>
        <Helmet>
          <title>
            {this.props.data.wordpressPage.yoast_head_json.title}
          </title>
          <meta
            name="description"
            content={
              this.props.data.wordpressPage.yoast_head_json.description
            }
          />
        </Helmet>
        <PagesHero className="hero-section">
          <div className="Hero">
            <div className="InnerBox">
              <div className="title">
                <Heading>Frequently Asked Questions</Heading>
              </div>
            </div>
          </div>
        </PagesHero>
        <MainSection>
          <div className="Options">
            {faqs.map(({ node }) => {
              return (
                <AccordionComponent
                  key={node.wordpress_id}
                  clicked={() => this.accordionChangeHandler(node.wordpress_id)}
                  accState={this.state.activeAcc === node.wordpress_id}
                  id={node.wordpress_id}
                  title={ReactHtmlParser(node.title)}
                  content={ReactHtmlParser(node.content)}
                />
              );
            })}
          </div>

          <div className="Contact">
            <ContactBox>
              <h2>Have more questions or concerns?</h2>
              <FontAwesomeIcon id="icon" icon={faPhone} />
              <h3>800.730.5957</h3>
              <p class="contact-time">9:00 AM - 5:00 PM EST</p>
              <span>Monday - Friday</span>
            </ContactBox>
          </div>
        </MainSection>
      </Div>
    );
  }
}

export default Faqs;

export const query = graphql`
  query {
    allWordpressWpFaq {
      edges {
        node {
          wordpress_id
          title
          content
          #faq_category
        }
      }
    }
    wordpressPage(slug: { eq: "faq" }) {
      yoast_head_json {
        title
        description
      }
      acf {
        hero_image {
          source_url
        }
      }
    }
  }
`;
